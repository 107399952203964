<template>
   <div class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>发券中心</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <div class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>任务名称：</label>
                  <el-input v-model="param.name" placeholder="任务名称" clearable></el-input>
               </el-row>
               <el-button class="bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)">搜索</el-button>
               <el-button icon="el-icon-refresh-right" plain @click="handleQuery(false)">重置</el-button>
            </el-row>
         </el-row>
         <el-row class="table-box">
            <el-row type="flex" justify="space-between">
               <el-tabs v-model="param.status" @tab-click="getTaskList">
                  <el-tab-pane label="全部"></el-tab-pane>
                  <el-tab-pane label="执行中" name="EXECUTORY"></el-tab-pane>
                  <el-tab-pane label="已结束" name="END"></el-tab-pane>
               </el-tabs>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" @click="createTask">创建任务</el-button>
               </el-row>
            </el-row>
            <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                :stripe="true"
                border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="name" label="任务名称" min-width="70"></el-table-column>
               <el-table-column prop="num" label="发送人数" min-width="70"></el-table-column>
               <el-table-column label="卡券" min-width="70">
                  <template slot-scope="scope">
                     <el-button @click="handleCoupon('LOOK', scope.row.id)">查看卡券</el-button>
                  </template>
               </el-table-column>
               <el-table-column prop="sendTime" label="发送时间"></el-table-column>
               <el-table-column label="状态">
                  <template slot-scope="scope">
                     {{ scope.row.status | filterStatus(that) }}
                  </template>
               </el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button type="text" @click="lookTask(scope.row)" >
                        <el-link type="primary">查看</el-link>
                     </el-button>
                     <el-button type="text" v-if="scope.row.status === 'EXECUTORY'" @click="sendCard(scope.row.id)">
                        <el-link type="primary">发送</el-link>
                     </el-button>
                     <el-button type="text" style="color: #F56C6C;" @click.stop="delTask(scope.row.id)">
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </div>
      <!-- 管理/查看优惠券 -->
      <el-dialog :title="`${ actCard === 'LOOK' ? '查看卡' : '添加优惠' }券`" :visible.sync="visibleCoupon" width="550px">
         <el-table height="500px" style="overflow-y: auto" :data="actCard === 'LOOK' ? ruleForm.couponGivenItemList : couponData" @selection-change="selectionCoupon">
            <el-table-column type="selection" width="55" v-if="actCard === 'MANAGE'"></el-table-column>
            <el-table-column label="序号" v-else>
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column prop="name" label="优惠券名称"></el-table-column>
            <el-table-column label="数量">
               <template slot-scope="scope">
                  <el-input v-if="actCard === 'MANAGE'" v-model.number="scope.row.quantity"></el-input>
                  <p v-else>{{ scope.row.quantity }}</p>
               </template>
            </el-table-column>
         </el-table>
         <span slot="footer">
            <el-button @click="visibleCoupon = false">取消</el-button>
            <el-button v-if="actCard === 'MANAGE'" class="bg-gradient" @click="saveCoupon">确定</el-button>
         </span>
      </el-dialog>
      <!-- 管理/查看预售券 -->
      <el-dialog :title="`${ actCard === 'LOOK' ? '查看' : '添加' }预售券`" :visible.sync="visiblePreSale" width="550px">
         <el-table height="500px" style="overflow-y: auto" :data="preSaleData" @selection-change="selectionPreSale">
            <el-table-column type="selection" width="55" v-if="actCard === 'MANAGE'"></el-table-column>
            <el-table-column label="序号" v-else>
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column prop="name" label="预售券名称"></el-table-column>
            <el-table-column label="数量">
               <template slot-scope="scope">
                  <el-input v-if="actCard === 'MANAGE'" v-model.number="scope.row.quantity"></el-input>
                  <p v-else>{{ scope.row.quantity }}</p>
               </template>
            </el-table-column>
         </el-table>
         <span slot="footer">
            <el-button @click="visiblePreSale = false">取消</el-button>
            <el-button v-if="actCard === 'MANAGE'" class="bg-gradient" @click="savePreSale">确定</el-button>
         </span>
      </el-dialog>
      <!-- 创建任务 -->
      <el-dialog title="创建任务" :visible.sync="visibleTask" :close-on-click-modal="false" width="650px" :before-close="closeTask">
         <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
            <el-form-item label="任务名称" prop="name">
               <el-input class="width-280" v-model.trim="ruleForm.name" placeholder="任务名称" :disabled="actTask === 'LOOK'"></el-input>
            </el-form-item>
            <el-form-item label="任务时间" required>
               <el-select class="width-280" v-model="ruleForm.runWay" placeholder="请选择" :disabled="actTask === 'LOOK'">
                  <el-option v-for="(val, key) in { AUTOMATIC: '定时发送', MANUAL: '手动发送' }"
                              :key="key" :label="val" :value="key"></el-option>
               </el-select>
               <div class="m-left-78 m-top-10" v-if="ruleForm.runWay === 'AUTOMATIC'">
                  <el-date-picker v-model="ruleForm.timeLong" type="datetime" placeholder="选择日期时间"
                                  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>至
                  <el-date-picker v-model="ruleForm.timeLong" type="datetime" placeholder="选择日期时间"
                                  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
               </div>
            </el-form-item>
            <el-form-item label="指定人员" prop="couponGivenUserList">
               <el-button @click="handleUser">管理人员</el-button><span :class="ruleForm.couponGivenUserList.length > 0 ? '' : 'color'">{{ ruleForm.couponGivenUserList.length > 0 ? '已' : '未' }}选择</span>
            </el-form-item>
            <el-form-item label="指定发券" prop="couponGivenItemList">
               <el-button @click="handleCoupon('MANAGE')" :disabled="actTask === 'LOOK'">添加优惠券</el-button>
               <el-button @click="handlePreSale('MANAGE')" :disabled="actTask === 'LOOK'">添加预售券</el-button>
            </el-form-item>
            <el-table :data="ruleForm.couponGivenItemList" border height="200px" style="overflow-y: auto">
               <el-table-column prop="name" label="卡券名称"></el-table-column>
               <el-table-column prop="quantity" label="数量"></el-table-column>
            </el-table>
         </el-form>
         <span slot="footer">
            <el-button @click="closeTask">取 消</el-button>
            <el-button type="primary" @click="saveTask" v-if="actTask === 'ADD'">确 定</el-button>
         </span>
      </el-dialog>
      <!-- 用户搜索 -->
      <el-dialog title="用户搜索" :close-on-click-modal="false" :visible.sync="visibleSearch" width="550px" >
         <search-user ref="searchUser" name="issuingCenter" :disabled="actTask === 'LOOK'" :selectedMember="ruleForm.couponGivenUserList" @emitMemberList="getSelectMember"/>
         <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="visibleSearch = false">取 消</el-button>
            <el-button size="medium" type="primary" @click="saveMember" v-if="actTask === 'ADD'">确 定</el-button>
         </div>
      </el-dialog>
   </div>
</template>
<script>
import { business, urlObj } from "@/api/interface";
import { issuing, coupon } from '@/api/interface/business'
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common";
import searchUser from '@/components/local/searchUser'
export default {
   name: 'issuingCenter',
   data() {
      return {
         that: this,
         tableData: [],
         loading: true,
         total: 0,
         visibleCoupon: false,
         visiblePreSale: false,
         visibleTask: false,
         visibleSearch: false,
         actCard: '',
         actTask: '',
         couponData: [],
         preSaleData: [],
         selectedMember: [],
         selectedCoupon: [],
         savedPreSale: [],
         selectedPreSale: [],
         savedCoupon: [],
         param: {
            status: '',
            name: '',
         },
         limit: 0,
         page: 1,
         ruleForm: {
            name: '',
            runWay: 'MANUAL',
            couponGivenUserList: [],
            couponGivenItemList: []
         },
         rules: {
            name: [{ required: true, message: '任务名称不能为空！', trigger: 'blur' }],
            couponGivenUserList: [{ required: true, message: '指定人员不能为空！' }],
            couponGivenItemList: [{ required: true, message: '指定发券不能为空！' }]
         }
      }
   },
   components: {
      searchUser
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   mounted() {
      getDict('send-card-status')
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getTaskList()
   },
   methods: {
      // 获取任务列表
      getTaskList() {
         const url = issuing.getPage + `?limit=${ this.limit }&page=${ this.page }`
         const param = { ...this.param, companyId: this.hotelInfo.storeId, hotelId: this.hotelInfo.id }
         this.param.status === '0' && (param.status = '')
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return
            this.loading = false
            this.total = res.total
            this.tableData = res.records
         })
      },
      // 改变每页数
      changePagNum(num) {
         this.limit = num
         this.getTaskList()
      },
      // 改变当前页
      changeCurrPage(num) {
         this.page = num
         this.getTaskList()
      },
      // 查询发券列表
      handleQuery(bool) {
         this.page = 1
         if (bool) return this.getTaskList()
         this.param.name = ''
      },
      // 创建任务
      createTask() {
         this.actTask = 'ADD'
         this.actCard = 'MANAGE'
         this.visibleTask = true
         this.getCouponList()
         this.getPreSaleList()
      },
      // 指定人员
      handleUser() {
         this.visibleSearch = true
         if (this.actTask === 'LOOK') this.$nextTick(() => this.$refs.searchUser.getSelectedMember())
      },
      // 获取选中会员
      getSelectMember(memberList) {
         this.selectedMember = memberList
      },
      // 保存会员
      saveMember() {
         this.visibleSearch = false
         this.ruleForm.couponGivenUserList = this.selectedMember.map(item => ({ companyId: item.companyId, memberId: item.id }))
      },
      // 添加优惠券
      handleCoupon(actCard, id) {
         this.actCard = actCard
         this.visibleCoupon = true
         this.actCard === 'LOOK' && this.getGivenCards(id)
      },
      // 获取优惠券列表
      getCouponList() {
         const url = coupon.couponList,
               param = { companyId: this.hotelInfo.storeId, status: 'PROCESSING', thresholdType: 'GIFT' };
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success || !res.records) return
            this.couponData = res.records.map(item => ({ id: item.id, name: item.mainTitle, quantity: 1, companyId: item.companyId }))
         })
      },
      // 获取选中优惠券
      selectionCoupon(val) {
         this.selectedCoupon = val
      },
      // 保存优惠券
      saveCoupon() {
         this.visibleCoupon = false
         this.savedCoupon = this.selectedCoupon.map(i => {
            return { couponId: i.id, name: i.name, companyId: i.companyId, quantity: i.quantity, type: 'COUPON' }
         })
         this.ruleForm.couponGivenItemList = [ ...this.savedCoupon, ...this.savedPreSale ]
      },
      // 添加预售券
      handlePreSale(actCard) {
         this.actCard = actCard
         this.visiblePreSale = true
      },
      // 获取预售券列表
      getPreSaleList() {
         const url = business.preSaleList,
               param = { hotelId: this.hotelInfo.id, useType: 'GIFT', status: 'PROCESSING' };
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return
            this.preSaleData = res.records.map(item => ({ id: item.id, name: item.mainTitle, quantity: 1, companyId: item.companyId }))
         })
      },
      // 获取选中预售券
      selectionPreSale(val) {
         this.selectedPreSale = val
      },
      // 保存预售券
      savePreSale() {
         this.visiblePreSale = false
         this.savedPreSale = this.selectedPreSale.map(i => {
            return { couponId: i.id, name: i.name, companyId: i.companyId, quantity: i.quantity, type: 'COUPON_PRESELL' }
         })
         this.ruleForm.couponGivenItemList = [ ...this.savedCoupon, ...this.savedPreSale ]
      },
      // 保存任务
      saveTask() {
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            const url = issuing.create
            const param = { ...this.ruleForm, companyId: this.hotelInfo.storeId, hotelId: this.hotelInfo.id }
            this.$axios.post(url, param, 'json').then(res => {
               if (!res.success) return
               this.closeTask()
               this.getTaskList()
               this.$message({ message: '创建成功！', type: 'success' })
            })
         })
      },
      // 关闭任务
      closeTask() {
         this.visibleTask = false
         this.ruleForm.name = ''
         this.ruleForm.runWay = 'MANUAL'
         this.ruleForm.couponGivenUserList.length = 0
         this.ruleForm.couponGivenItemList.length = 0
         this.couponData.length = 0
         this.savedCoupon.length = 0
         this.savedPreSale.length = 0
         this.$refs.searchUser && this.$refs.searchUser.reset()
      },
      // 查看任务
      lookTask(row) {
         this.actTask = 'LOOK'
         this.visibleTask = true
         this.ruleForm.name = row.name
         this.ruleForm.runWay = row.runWay
         this.getGivenUsers(row.id)
         this.getGivenCards(row.id)
      },
      // 获取指定用户列表
      getGivenUsers(givenId) {
         const url = issuing.givenUsers,
             param = { givenId };
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return
            this.ruleForm.couponGivenUserList = res.records.map(item => ({ value: (item.memberDTO && item.memberDTO.nickName) || '', id: item.id }))
         })
      },
      // 获取指定优惠/预售券列表
      getGivenCards(givenId) {
         const url = issuing.givenCards,
             param = { givenId };
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return
            this.ruleForm.couponGivenItemList = res.records.map(item => ({ name: item.name, quantity: item.quantity || 0 }))
         })
      },
      // 发送卡券
      sendCard(id) {
         this.$confirm('是否确定发送卡券?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            const url = issuing.send + `/${ id }`
            this.$axios.post(url, {}).then(res => {
               if (!res.success) return
               this.$message({ message: '发送成功！', type: 'success' })
               this.getTaskList()
            })
         })
      },
      // 删除任务
      delTask(id) {
         this.$confirm('此操作将永久删除, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            const url = issuing.delete + `/${ id }`
            this.$axios.post(url, {}).then(res => {
               if (!res.success) return
               this.$message({ message: '删除成功！', type: 'success' })
               this.getTaskList()
            })
         })
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getTaskList()
      }
   },
   filters: {
      filterStatus(val, that) {
         if (val && that.dictData['send-card-status']) return that.dictData['send-card-status'][val] || val
      }
   }
}
</script>
<style lang="scss" scoped>
.cont{
   .m-left-78{ margin-left: 78px }
   ::v-deep .el-form-item__error{ margin-left: 78px }
   .color{ color: red }
}
</style>
